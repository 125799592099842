import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Styled404 = styled.section`
	padding: 10rem 1.5rem;

	.button {
		margin-top: 5rem;
	}
`;

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Page Not Found" />
		<Styled404 className="section">
			<div className="container">
				<div className="columns is-centered">
					<div className="column has-text-centered">
						<h1>404</h1>
						<p>We're sorry, but the page you are looking for doesn't exist.</p>
						<Link className="button button-2 with-arrow" to="/">
							Go Home
						</Link>
					</div>
				</div>
			</div>
		</Styled404>
	</Layout>
);

export default NotFoundPage;
